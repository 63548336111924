var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadRemoteModule } from '@angular-architects/module-federation-runtime';
import '@ocp/components/dist/index.css';
import { camelize, getDistinctArray, getFederationsEntrypointUrl } from './utils';
const MODULE_ELEMENTS_SELECTOR = '[ocp-loader]';
function loadModule(kbModuleName, baseUrl) {
    return loadRemoteModule({
        type: 'script',
        remoteEntry: `${baseUrl}/${kbModuleName}-remote.js`,
        remoteName: camelize(kbModuleName),
        exposedModule: 'main',
    });
}
function loadModulesForCustomTags(baseUrl, targetNode = document) {
    return __awaiter(this, void 0, void 0, function* () {
        const allRequiredModuleTags = Array.from(targetNode.querySelectorAll(MODULE_ELEMENTS_SELECTOR))
            .map((moduleElement) => moduleElement.tagName);
        const requiredModuleTags = getDistinctArray(allRequiredModuleTags);
        try {
            const loadedModules = yield Promise.all(requiredModuleTags
                .map((requiredModuleTag) => __awaiter(this, void 0, void 0, function* () {
                const moduleName = requiredModuleTag.toLowerCase();
                // TODO: Enclose each module loading by try-catch block? Investigate errors reporting habits of the
                // loadRemoteModule func.
                return [moduleName, yield loadModule(moduleName, baseUrl)];
            })));
            console.log('Loaded OCP modules for custom tags:', loadedModules.map(([moduleName]) => moduleName));
        }
        catch (e) {
            console.error('Error loading modules', e);
        }
    });
}
function injectGlobalLoader(baseUrl) {
    if (!window.KbModuleLoader) {
        window.KbModuleLoader = {
            require(kbModuleName, successCallback, failCallback) {
                return __awaiter(this, void 0, void 0, function* () {
                    let module = null;
                    let loadingError = null;
                    try {
                        module = yield loadModule(kbModuleName, baseUrl);
                        console.log(`Loaded OCP module '${kbModuleName}'`);
                    }
                    catch (e) {
                        loadingError = e;
                        console.error(`Error loading OCP module '${kbModuleName}':`, e);
                    }
                    // The setTimeout is intended to defer a callback invocation, so if it raises an uncaught exception, it does not
                    // affect the execution of require (this is important, for example, when the require method is used inside a
                    // loop) and do print details of the exception to the console.
                    setTimeout(() => {
                        if (!loadingError) {
                            successCallback === null || successCallback === void 0 ? void 0 : successCallback(module);
                        }
                        else {
                            failCallback === null || failCallback === void 0 ? void 0 : failCallback(loadingError);
                        }
                    });
                });
            },
            version: CONTAINER_VERSION,
        };
    }
}
const baseUrl = getFederationsEntrypointUrl(document.currentScript);
loadModulesForCustomTags(baseUrl);
injectGlobalLoader(baseUrl);
const loadPortletModules = (portletNode) => {
    loadModulesForCustomTags(baseUrl, portletNode);
};
window.loadPortletModules = loadPortletModules;
